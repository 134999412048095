import { Button, Grid, Input, InputGroup, InputLeftElement, Stack, Text } from '@chakra-ui/react';
import { CSSProperties, useEffect, useState } from 'react';
import { Search } from 'react-feather';
import theme from '../theme';
import DocumentCard from '../components/DocumentCard';
import { useAIsearchQuery, useSearchQuery } from '../lib/api/GraphAPI';

function Header() {
  return (
    <div style={{ width: '100%', padding: '20px' }}>
      <Text fontWeight={700} fontSize={'xx-large'}>
        Case-search
      </Text>
    </div>
  );
}

function Searchbar(props: { style?: CSSProperties; query: string; setQuery: (query: string) => void }) {
  const [debouncedQuery, setDebouncedQuery] = useState(props.query);
  // UseEffect to implement the debouncing
  useEffect(() => {
    const handler = setTimeout(() => {
      console.log('query sent: ' + debouncedQuery);
      props.setQuery(debouncedQuery); // Update the parent's query state after 5 seconds
    }, 1000); // 5 seconds debounce delay

    // Cleanup function: clears timeout if the component unmounts or the input changes before 5 seconds
    return () => {
      clearTimeout(handler);
    };
  }, [debouncedQuery]); // Only trigger when `debouncedQuery` changes

  return (
    <div style={{ ...props.style }}>
      <InputGroup>
        <InputLeftElement>
          <Search />
        </InputLeftElement>
        <Input onChange={(e) => setDebouncedQuery(e.target.value)} placeholder="Search for a case..." width={'536px'} backgroundColor={'white'} value={debouncedQuery} />
      </InputGroup>
    </div>
  );
}

function Searchtype(props: { style?: CSSProperties; searchMode: 'exact' | 'context'; setSearchMode: (searchMode: 'exact' | 'context') => void }) {
  return (
    <div
      style={{
        ...props.style,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Text fontWeight={500} fontSize={'small'}>
        Search type:
      </Text>
      <Button variant={'solid'} colorScheme={props.searchMode === 'exact' ? 'purple' : 'gray'} marginLeft={'10px'} size={'sm'} onClick={() => props.setSearchMode('exact')}>
        Exact match
      </Button>
      <Button size={'sm'} colorScheme={props.searchMode === 'context' ? 'purple' : 'gray'} onClick={() => props.setSearchMode('context')}>
        Contextual
      </Button>
    </div>
  );
}

function AISearchResults(props: { style?: CSSProperties; query: string }) {
  const { data, error, isLoading, isSuccess } = useAIsearchQuery(props.query);
  if (isSuccess && data)
    return (
      <Grid
        style={{
          ...props.style,
          height: '100%',
          backgroundColor: theme.colors.gray[100],
          borderRadius: '10px',
          padding: '10px',
          overflowY: 'scroll',
          scrollbarWidth: 'thin',
        }}
        templateColumns="repeat(3, 1fr)"
        gap={4}
      >
        {data.nodes.map((node) => (
          <DocumentCard key={node.properties.id} id={node.properties.id} />
        ))}
      </Grid>
    );
}
function SearchResults(props: { style?: CSSProperties; query: string }) {
  const { data, error, isLoading, isSuccess } = useSearchQuery(props.query);

  if (isSuccess && data)
    return (
      <Grid
        style={{
          ...props.style,
          height: '100%',
          backgroundColor: theme.colors.gray[100],
          borderRadius: '10px',
          padding: '10px',
          overflowY: 'scroll',
          scrollbarWidth: 'thin',
        }}
        templateColumns="repeat(3, 1fr)"
        gap={4}
      >
        {data.map((data) => (
          <DocumentCard key={data.properties.id} id={data.properties.id} />
        ))}
      </Grid>
    );
}
export default function SearchPage() {
  const [searchMode, setSearchMode] = useState<'exact' | 'context'>('exact');
  const [query, setQuery] = useState('');

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        overflow: 'hidden',
      }}
    >
      <Header />
      <Stack direction="row" spacing={4} padding={'10px'}>
        <Searchbar query={query} setQuery={setQuery} />
        <Searchtype searchMode={searchMode} setSearchMode={setSearchMode} />
      </Stack>
      {searchMode === 'context' ? <AISearchResults query={query} /> : <SearchResults query={query} />}
    </div>
  );
}

import { Heading, Stack, Accordion, IconButton, Text, Select } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from '../../lib/hooks';
import { ChevronRightIcon, PlusSquareIcon } from '@chakra-ui/icons';
import { motion } from 'framer-motion';
import SavedFolder from './SavedFolder';
import { uniqueNamesGenerator, Config, adjectives, animals } from 'unique-names-generator';
import { addFolder, incrementRefreshCounter, removeDoc, setToolOpenStatus } from '../../lib/state/slices/ViewSlice';
import theme from '../../theme';
import { ChevronRight } from 'react-feather';
import { useState } from 'react';
import SavedDocumentCard from './SavedDocumentCard';
import DocumentCard from '../DocumentCard';

export default function SavedDocumentsList() {
  const open = useAppSelector((state) => state.viewReducer.toolOpenStatus.savedCases);
  const folders = useAppSelector((state) => state.viewReducer.folders);
  const toolOpenStatus = useAppSelector((state) => state.viewReducer.toolOpenStatus);
  const dispatch = useAppDispatch();
  const [openFolder, setOpenFolder] = useState<string | null>(null);
  const variants = {
    open: { width: '460px', padding: '10px' },
    closed: { width: '30px', padding: '0px' },
  };
  const expand_button_variants = {
    open: { transform: 'rotate(0deg)' }, // rotate button by 180deg
    closed: { transform: 'rotate(180deg)' },
  };
  const header_variants = {
    open: { opacity: 1, display: 'block', whiteSpace: 'nowrap' }, // disallow linewrap with whitespace: nowrap
    closed: { display: 'none', opacity: 0, whiteSpace: 'nowrap' },
  };

  return (
    <motion.div variants={variants} animate={open ? 'open' : 'closed'}>
      <div style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', overflow: 'clip' }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent={'space-between'}
          minHeight={'46px'}
          onClick={() => {
            dispatch(incrementRefreshCounter());
            dispatch(setToolOpenStatus({ ...toolOpenStatus, savedCases: !open }));
          }}
          style={{ cursor: 'pointer' }}
        >
          <Stack direction={'row'} alignItems={'center'}>
            <motion.div variants={expand_button_variants} animate={open ? 'open' : 'closed'}>
              <ChevronRight />
            </motion.div>
            <motion.div variants={header_variants} animate={open ? 'open' : 'closed'}>
              <Text fontWeight={700} color={theme.colors.gray[700]} fontSize="large">
                Saved Cases
              </Text>
            </motion.div>
          </Stack>
        </Stack>
        <motion.div variants={header_variants} style={{ overflowY: 'hidden', scrollbarWidth: 'none' }}>
          <Stack direction={'column'} justifyContent={'center'} alignItems={'center'} paddingBottom={'20px'}>
            <Select placeholder="Select Folder" borderColor={theme.colors.purple[700]} borderRadius={'10px'} borderWidth={'2px'} size={'sm'} width={'80%'} colorScheme="purple" onChange={(e) => setOpenFolder(e.target.value)}>
              {folders.map((folder, index) => (
                <option key={folder.id} value={folder.id}>
                  {folder.name}
                </option>
              ))}
            </Select>
          </Stack>
        </motion.div>
        <motion.div variants={header_variants} style={{ flex: 1, overflowY: 'scroll', scrollbarWidth: 'thin' }}>
          <Stack direction={'column'} justifyContent={'center'} alignItems={'center'} padding={'10px'}>
            {openFolder ? folders.find((folder) => folder.id === openFolder)?.documents.map((doc) => <DocumentCard key={doc} id={doc} nodeId="" onClose={() => dispatch(removeDoc({ folderId: openFolder, nodeId: doc }))} />) : null}
          </Stack>
        </motion.div>
      </div>
    </motion.div>
  );
}

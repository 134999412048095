import { Graph as GraphAPIType, Node, Relationship, chambertypes, countrytypes, importancetypes } from './api/APITypes';
import Graph from 'graphology';
import { GraphView } from './state/ViewTypes';
import { theme } from '@chakra-ui/theme';

function graphFromData(props: { data: GraphAPIType; centerNode: string }): Graph {
  const graph = new Graph({ type: 'mixed' });
  for (let i = 0; i < props.data.nodes.length; i++) {
    let node = props.data.nodes[i];
    addNodeToGraph(node, graph, node.properties.id === props.centerNode);
  }
  for (let i = 0; i < props.data.edges.length; i++) {
    let edge = props.data.edges[i];
    addEdgeToGraph(edge, graph);
  }
  return graph;
}

function addNodeToGraph(node: Node, graph: Graph, isCenter?: boolean) {
  graph.addNode(node.elementId, {
    label: node.properties.label?.replace("CASE OF", ''),
    id: node.properties.id,
    judgementdate: node.properties.judgementdate,
    nodeType: node.labels[0],
    importance: node.properties.importance,
    country: node.properties.country,
    conclusion: node.properties.conclusion,
    chambertype: node.properties.chambertype,
    separateopinion: node.properties.separateopinion,
    keywords: node.properties.keywords,
    x: new Date(node.properties.judgementdate!).getTime(),
    y: Math.random() * 200000000000 - 200000000000 / 2,
    color: isCenter ? theme.colors.purple[400] : getNodeColor(node.properties.importance!.toString()),
    originalColor: isCenter ? theme.colors.purple[400] : getNodeColor(node.properties.importance!.toString()),
    size: 5,
    violation: node.properties.violation,
    nonviolation: node.properties.nonviolation,
    url: node.properties.url,
  });
}

function addEdgeToGraph(edge: Relationship, graph: Graph) {
  graph.addDirectedEdgeWithKey(edge.elementId, edge.startNodeElementId, edge.endNodeElementId, { label: edge.type, size: 3 });
}

function getDefaultGraphView(centerNode: string): GraphView {
  let graphView: GraphView = {
    name: 'Graph',
    centerNode: centerNode,
    floatingDocuments: [],
    nodeContextMenu: undefined,
    filter: {
      decisionLevel: chambertypes,
      importance: importancetypes,
      timeFilter: { lower: 0, upper: new Date().getTime() },
      countrys: countrytypes,
    },
  };
  return graphView;
}

function getNodeColor(type: string) {
  switch (type) {
    case 'Keyword':
      return '#fff873';
    case 'Article':
      return '#065666';
    case 'Paragraph':
      return '#ff6e6e';
    case 'SubParagraph':
      return '#ffbfbf';
    case 'Document':
      return '#6B46C1';
    case '0':
      return theme.colors.green[400];
    case '1':
      return theme.colors.blue[900];
    case '2':
      return theme.colors.blue[700];
    case '3':
      return theme.colors.blue[400];
    case '4':
      return theme.colors.blue[200];

    default:
      return '#697aff';
  }
}

export { graphFromData, getNodeColor, addNodeToGraph, addEdgeToGraph, getDefaultGraphView };

import { Text } from '@chakra-ui/react';
import { RangeSlider, RangeSliderTrack, RangeSliderFilledTrack, RangeSliderThumb } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../lib/hooks';
import { useSigma } from '@react-sigma/core';
import { useState } from 'react';
import { setTimeFilter } from '../../../lib/state/slices/ViewSlice';
import { motion } from 'framer-motion';

function Thumb(props: { label?: string }) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', marginTop: '15px', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{ background: 'gray', width: '10px', height: '30px', borderRadius: '10px' }} />{' '}
      <Text fontSize="x-small" marginTop="3px" minWidth="2px" minHeight="15px">
        {props.label}
      </Text>{' '}
    </div>
  );
}

export default function TimeFilter(props: { style: React.CSSProperties | undefined; graphViewIndex: number }) {
  const [upperDate, setUpperDate] = useState(new Date().getTime());
  const [lowerDate, setLowerDate] = useState(0);
  const dispatch = useDispatch();
  const graph = useSigma().getGraph();
  const centerNode = useAppSelector((state) => state.viewReducer.graphViews[props.graphViewIndex].centerNode);
  const open = useAppSelector((state) => state.viewReducer.toolOpenStatus.timeFilter);
  const variants = {
    open: { opacity: 1 },
    closed: { opacity: 0, display: 'none' },
  };
  return (
    <motion.div variants={variants} animate={open ? 'open' : 'closed'}>
      <div
        style={{
          width: '50%',
          ...props.style,
          height: 'fit-content',
          padding: '14px',
          backgroundColor: 'rgba(255,255,255,0.9)',
          borderRadius: '5px',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
          <Text fontSize="small" as="b" textAlign="center">
            Show cases from
          </Text>
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginLeft: '30px' }}>
            <RangeSlider
              width="100%"
              min={0}
              max={new Date().getTime()}
              step={86400000}
              defaultValue={[0, new Date().getTime()]}
              colorScheme="gray"
              onChange={(val) => {
                setLowerDate(val[0]);
                setUpperDate(val[1]);
              }}
              onChangeEnd={(val) => {
                dispatch(setTimeFilter({ timeFilter: { lower: val[0], upper: val[1] }, graphViewIndex: props.graphViewIndex }));
              }}
            >
              <RangeSliderTrack bg="#A0AEC0">
                <RangeSliderFilledTrack />
              </RangeSliderTrack>
              <RangeSliderThumb index={0}>
                <Thumb label={new Date(lowerDate).getFullYear() > 1972 ? new Date(lowerDate).getFullYear().toString() : undefined} />
              </RangeSliderThumb>
              <RangeSliderThumb index={1}>
                <Thumb label={new Date(upperDate).getFullYear() < 2022 ? new Date(upperDate).getFullYear().toString() : undefined} />
              </RangeSliderThumb>
            </RangeSlider>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '10px' }}>
              <Text fontSize="x-small">{1970}</Text>
              <Text fontSize="x-small">{new Date().getFullYear()}</Text>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}


import {
  Card,
  CardBody,
  CardFooter,
  Text,
  Image,
  OrderedList,
  ListItem,
  Box,
  Accordion,
  AccordionItem,
  AccordionPanel,
  AccordionButton,
  AccordionIcon,
  Container,
} from "@chakra-ui/react";
import { Heading } from "@chakra-ui/react";

type Example = {
  //lowest level box to make the examples within the card
  heading: string;
  description: string;
  imageUrl?: string;
};

type GuideCard = {
  //middle box that has examples in it
  heading: string;
  description: string;
  examples: Example[];
};

type GuideStep = {
  //outer box that has cards in it
  heading: string;
  description: string;
  guideCards: GuideCard[];
};

const listOfGuideSteps: GuideStep[] = [
  //here you put in the data you dumbdumb
  {
    heading: "Find a starting case",
    description:
      "Begin your research by locating a relevant starting case. This could be a case you’re familiar with, or one suggested by the tool’s recommendation engine.",
    guideCards: [
      {
        heading: "Browsing Suggested Cases",
        description:
          "You can choose a case from the ones recommended by the Council of Europe. These can be found by clicking recommended cases, selecting a right from the ECHR, and choosing a keyword within this topic. You can also use filters to select country, importance level and sort by date.",
        examples: [
          { heading: "Quick example with default settings", description: "yo" },
          { heading: "Full example with filtering", description: "asnf" },
        ],
      },
      {
        heading: "Using Keywords or Context to Search",
        description:
          "Enter keywords or full sentences related to your research topic to find a case that best matches your interests. The tool will display a list of related cases.",
        examples: [
          { heading: "Example of a keyword search", description: "description" },
          {
            heading: "Example of a contextual search",
            description: "here is an example ur welcome",
          },
        ],
      },
    ],
  },
  {
    heading: "Open the Case in the Graph Viewer",
    description:
      "Once you've found a relevant case, open it in the graph viewer to explore the connections and relationships between it and other cases.",
    guideCards: [
      {
        heading: "Navigating the Graph Viewer",
        description:
          "Use the navigation tools to zoom in and out, or pan across the graph to explore connected cases. From oldest on the left, to newer cases on the left.",
        examples: [
          { heading: "Example of zooming in for more details", description: "description" },
          { heading: "Example of using the timeline", description: "description" },
        ],
      },
      {
        heading: "Viewing Case Details",
        description:
          "Click on a specific node to view detailed information about that case, including its related keywords, case facts, and any linked resources.",
        examples: [
          { heading: "Example of finding key case details", description: "description" },
          { heading: "Example of opening in HUDOC", description: "description" },
        ],
      },
      {
        heading: "Filter to Only See Relevant Cases",
        description:
          "Use the filter function to remove less important cases, or to only view cases with a specific feature.",
        examples: [
          { heading: "Example with quick filtering", description: "description" },
          { heading: "Example with advanced filtering", description: "description" },
        ],
      },
    ],
  },
  {
    heading: "Research the Connections Between the Cases",
    description:
      "Investigate how cases are related by following the connections in the graph viewer. This step helps you understand the broader context or network around your selected case.",
    guideCards: [
      {
        heading: "Exploring Direct Connections",
        description:
          "Hover or Click on Nodes to study the cases that are directly connected to your starting case. These could provide crucial insights or further reading material.",
        examples: [
          { heading: "Example of hovering", description: "description" },
          { heading: "Example of clicking", description: "description" },
        ],
      },
      {
        heading: "Discovering Indirect Relationships",
        description:
          "Look for more distant connections by expanding the graph with new indirectly related nodes that may highlight less obvious, yet important, relationships between cases.",
        examples: [
          {
            heading: "Example of expanding neighbours",
            description: "See second degree nodes",
          },
          {
            heading: "Example on how to get node recommendations",
            description: "description",
          },
        ],
      },
    ],
  },
  {
    heading: "Save Interesting Cases to View Later",
    description:
      "Mark cases that are of particular interest and save them for later review or to revisit after gathering more information..",
    guideCards: [
      {
        heading: "Adding to Your Saved Cases List",
        description:
          "Click the 'Save' button to add a case to one of your folders for easy access in the future.",
        examples: [
          {
            heading: "Example of saving a case from the graph",
            description: "description",
          },
          { heading: "Example of removing a case from saved cases", description: "description" },
        ],
      },
      {
        heading: "Organizing Saved Cases",
        description:
          "Create folders to organize saved cases by topic, importance, or research stage. Move the cases between folders by assigning it with the button.",
        examples: [
          { heading: "Example of making a folder", description: "description" },
          { heading: "Example of moving a case", description: "description" },
        ],
      },
    ],
  },
  {
    heading: "Compare the Cases",
    description:
      "After exploring the connections, compare the cases to identify patterns, differences, or trends that may support your research goals.",
    guideCards: [
      {
        heading: "Side-by-Side Comparison",
        description:
          "Open several cases within the graph to view two or more cases at once, examining their key attributes and connections.",
        examples: [
          { heading: "Example of side by side comparison", description: "description" },
        ],
      },
      {
        heading: "Saved Cases vs Graph Cases",
        description:
          "Open a case in the graph and view one of your previously saved cases to compare them side by side.",
        examples: [
          { heading: "Example of comparison from saved cases", description: "description" },
        ],
      },
    ],
  },
];

function GuideCard(props: {
  cardHeading: string;
  cardDescription: string;
  examples: Example[];
}) {
  return (
    <Card margin="12px">
      <CardBody>
        <Heading size="md" paddingBottom="12px">
          {props.cardHeading} {/* Main step heading */}
        </Heading>
        <Text>{props.cardDescription}</Text>
      </CardBody>

      <CardFooter>
        <Accordion allowMultiple width="100%">
          {props.examples.map((example, index) => (
            <AccordionItem key={index}>
              <h2>
                <AccordionButton>
                  <Box as="span" flex="1" textAlign="left">
                    <Text fontWeight="bold">{example.heading}</Text>{" "}
                    {/* Example heading */}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Text>{example.description}</Text>
                {example.imageUrl && (
                  <Image
                    src={example.imageUrl}
                    alt={example.heading}
                    objectFit="cover"
                    maxW="200px"
                    mt="4"
                  />
                )}
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </CardFooter>
    </Card>
  );
}

function GuideStep({ heading, description, guideCards }: GuideStep) {
  return (
    <Box marginBottom="24px">
      <Heading size="md" paddingBottom="12px">
        {heading}
      </Heading>
      <Text paddingBottom="12px">{description}</Text>
      {guideCards.map((card, index) => (
        <GuideCard
          key={index}
          cardHeading={card.heading}
          cardDescription={card.description}
          examples={card.examples}
        />
      ))}
    </Box>
  );
}

export default function GuidePage() {
  const guideSteps = { listOfGuideSteps };
  return (
    <Container
      style={{
        margin: "24px",
        width: "100%",
        height: "100%",
        display: "flex",
        alignContent: "center",
        justifyContent: "true",
        overflow: "scroll",
      }}
    >
      <Box>
        <Heading size="lg" paddingBottom="12px">
          How to use MESHR
        </Heading>
        <Text paddingBottom="12px">
          A quick user guide with helpful hints on how to get started with your
          research.
        </Text>
        <OrderedList>
          {listOfGuideSteps.map((step, index) => (
            <ListItem paddingBottom="12px">
              <GuideStep
                key={index}
                heading={step.heading}
                description={step.description}
                guideCards={step.guideCards}
              />
            </ListItem>
          ))}
        </OrderedList>
      </Box>
    </Container>
  );
}
